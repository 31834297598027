import {useState} from 'react';
import {useQuery} from '@apollo/client';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import Modal from '@hy-vee/web-core/lib/components/modal';

import LandingHero from '../components/landing/hero';
import BenefitCards from '../components/landing/benefit-cards';
import {getLandingPageContent} from '../graphql/queries/sanity-queries';
import {SANITY_STRATEGY} from '../enums/auth-types';
import PageContainer from '../components/page-container';
import VideoCard from '../components/landing/video-card';
import {toggleActiveModal} from '../action-creators';
import CheckDeliveryEligibilityModalWrapper from '../components/delivery/check-delivery-eligibility-modal-wrapper';
import LoadingIndicator from '../components/loading-indicator';
import Footer from '../components/landing/footer';
import CallToAction from '../components/landing/call-to-action';
import Questions from '../components/landing/questions';
import ImageBanner from '../components/image-banner';
import {getMyActiveCustomerMemberships} from '../graphql/queries/membership-queries';
import BonusPerkList from '../components/landing/bonus-perk-list';
import {useFuelSaverCard} from '../hooks/useFuelSaverCard';
import NonPlusMemberBanner from '../components/landing/non-plus-member-banner/non-plus-member-banner';
import BonusPerkDetailModal from '../components/bonus-perks/bonus-perk-detail-modal';

const Landing = ({activeModal, actions, isAuthenticated}) => {
    const [bonusPerkOfferDetails, setBonusPerkOfferDetails] = useState(null);
    const {data, loading} = useQuery(getLandingPageContent, {
        variables: {
            clientStrategy: SANITY_STRATEGY,
        },
    });

    const {data: membershipData, loading: membershipDataLoading} = useQuery(getMyActiveCustomerMemberships, {
        enabled: isAuthenticated,
    });

    const fuelSaverCard = useFuelSaverCard();

    if (loading || membershipDataLoading) {
        return <LoadingIndicator />;
    }

    const {
        hero,
        imageBanner,
        membershipBenefitCards,
        membershipBenefitVideoCard,
        conditionalPerksBannerForLoggedOutOrNotMember,
        membershipBenefitListHeader,
        nonPlusMemberCallToActionSection,
        plusMemberCallToActionSection,
        membershipQuestionsSection,
        footerContentRaw,
    } = data?.allCmsLoyaltyWebPlusLanding[0];

    const isMember = isAuthenticated && membershipData?.myCustomer?.customerMemberships.length;
    const heroContent = isMember ? hero.loggedInMemberHeroContent : hero.loggedOutHeroContent;
    const callToActionContent = isMember ? plusMemberCallToActionSection : nonPlusMemberCallToActionSection;
    const imageBannerContent = isMember ? null : imageBanner;

    return (
        <PageContainer>
            <LandingHero
                content={heroContent}
                image={hero.image} />
            <ImageBanner imageBanner={imageBannerContent} />
            <BenefitCards
                membershipBenefitCards={membershipBenefitCards}
                toggleDeliveryModal={actions.toggleActiveModal}
            />
            {isAuthenticated && isMember ?
                <BonusPerkList
                    bonusPerkListHeader={membershipBenefitListHeader}
                    fuelSaverCardUuid={fuelSaverCard?.fuelSaverCard?.fuelSaverCardUuid}
                    setBonusPerkOfferDetails={setBonusPerkOfferDetails} /> :
                <NonPlusMemberBanner content={conditionalPerksBannerForLoggedOutOrNotMember} />}
            <VideoCard membershipBenefitVideoCard={membershipBenefitVideoCard} />
            <Questions membershipQuestionsSection={membershipQuestionsSection} />
            <CallToAction
                callToAction={callToActionContent}
                toggleDeliveryModal={actions.toggleActiveModal}
            />
            <Footer content={footerContentRaw} />
            {activeModal && <CheckDeliveryEligibilityModalWrapper />}
            {bonusPerkOfferDetails &&
                <Modal
                    onClose={() => setBonusPerkOfferDetails(null)}
                    size="medium"
                    title="Offer Details"
                >
                    <BonusPerkDetailModal
                        bonusPerkDetails={bonusPerkOfferDetails}
                    />
                </Modal>}
        </PageContainer>
    );
};

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            toggleActiveModal,
        },
        dispatch,
    ),
});

const mapStateToProps = (state) => ({
    activeModal: state.activeModal,
    isAuthenticated: state.isAuthenticated,
});

export default connect(mapStateToProps, mapDispatchToProps)(Landing);
