import BlockContent from '@sanity/block-content-to-react';

import styles from './video-card.module.css';

const VideoCard = ({membershipBenefitVideoCard}) => (
    <div
        className={styles.videoCard}
        data-cy="video-card"
    >
        <div className={styles.videoContainer}>
            <iframe
                allowFullScreen=""
                frameBorder="0"
                height="100%"
                src={membershipBenefitVideoCard.videoUrl}
                title="Benefit Video"
                width="100%"
            />
        </div>
        <div className={styles.testimonialContainer}>
            <div className={styles.testimonialLeftQuote}>
                &ldquo;
            </div>
            <div className={styles.testimonial}>
                <BlockContent blocks={membershipBenefitVideoCard.testimonialContentRaw} />
            </div>
            <div className={styles.testimonialRightQuote}>
                &rdquo;
            </div>
            <p className={styles.testimonialName}>
                {'– '}
                {membershipBenefitVideoCard.testimonialName}
            </p>
        </div>
    </div>
);

export default VideoCard;
