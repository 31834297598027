import Section from '@hy-vee/web-core/lib/components/section';
import {Button} from '@hy-vee/design-system';

import Image from '../../shared/Image';

import styles from './hero.module.css';

const LandingHero = ({image, content}) =>
    <Section
        className={styles.hero}
        data-cy="hero"
    >
        <Image {...image} />
        <div className={styles.heroContent}>
            <img
                alt={content.image.alt}
                data-cy="hero-tagline-image"
                data-testid="hero-tagline-image"
                src={content.image.src}
            />
            <h1
                data-cy="hero-tagline"
                data-testid="hero-tagline"
            >
                {content.tagline}
            </h1>
            {
                content.minorTagline && <p className={styles.minorTagline}>{content.minorTagline}</p>
            }
            {
                content.additionalText && <p className={styles.additionalText}>{content.additionalText}</p>
            }
            <Button
                data-cy="hero-action-button"
                data-testid="hero-action-button"
                onClick={() => {
                    window.location.href = content.actionButton.destination;
                }}>
                {content.actionButton.text}
            </Button>
        </div>
    </Section>;

export default LandingHero;
