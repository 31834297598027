import {LinkButton} from '@hy-vee/web-core/lib/components/button';

import Image from '../../shared/Image';

import styles from './questions.module.css';

const Questions = ({membershipQuestionsSection}) => (
    <div
        className={styles.questions}
        data-cy="questions"
    >
        <div className={styles.questionsContainer}>
            <h3>{membershipQuestionsSection.heading}</h3>
            <p>{membershipQuestionsSection.subHeading}</p>

            <div className={styles.questionLinkContainer}>
                {
                    membershipQuestionsSection.links.map((link, index) => (
                        /* eslint-disable-next-line react/no-array-index-key */
                        <div key={`question-${index}`}>
                            {link.destination.startsWith('mailto:') && 'Or email us at '}
                            <LinkButton
                                as="a"
                                href={link.destination}
                            >
                                {link.text}
                                {' '}
                                &gt;
                            </LinkButton>
                        </div>
                    ))
                }
            </div>
        </div>
        <div className={styles.imageContainer}>
            <Image {...membershipQuestionsSection.employeeImage} />
        </div>

    </div>
);

export default Questions;
