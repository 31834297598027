import styled from 'styled-components';

const StyledFocusedContentContainer = styled.div`
    display: flex;
    justify-content: center;
    max-width: 100vw;
`;

const StyledFocusedContent = styled.div`
    max-width: ${(props) => props.maxwidth};
    width: 100%;
`;

const FocusedContent = ({children, maxwidth = '1200px'}) => (
    <StyledFocusedContentContainer>
        <StyledFocusedContent maxwidth={maxwidth}>{children}</StyledFocusedContent>
    </StyledFocusedContentContainer>
);

export default FocusedContent;
